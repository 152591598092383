@charset "utf-8";

@font-face {
    font-family: 'GorditaRegular';
    src: url('/fonts/GorditaRegular.woff2') format('woff2'),
        url('/fonts/GorditaRegular.woff') format('woff'),
        url('/fonts/GorditaRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GorditaBold';
    src: url('/fonts/GorditaBold.woff2') format('woff2'),
        url('/fonts/GorditaBold.woff') format('woff'),
        url('/fonts/GorditaBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GorditaLight';
    src: url('/fonts/GorditaLight.woff2') format('woff2'),
        url('/fonts/GorditaLight.woff') format('woff'),
        url('/fonts/GorditaLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GorditaMedium';
    src: url('/fonts/GorditaMedium.woff2') format('woff2'),
        url('/fonts/GorditaMedium.woff') format('woff'),
        url('/fonts/GorditaMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

$theme-colors: ("primary": #536dfe,
    "info": hsl(171, 100%, 41%));

$container-max-widths: (sm: 540px,
    //   sm: 580px,
    //   md: 720px,
    md: 760px,
    //   lg: 960px,
    lg: 1030px,
    //   xl: 1140px
    xl: 1250px);

$tb-module-color-1: #D6277B;
$tb-module-color-2: #EEAA39;
$tb-module-color-3: #2063D0;
$tb-module-color-4: #37C28B;

$font-main-color: #313957;

// Import has to be here
@import "bootstrap/bootstrap";

// // Required
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";

// // Optional
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/code";
// @import "../node_modules/bootstrap/scss/grid";

// @import "spacing-helpers.scss";

html {
    // https://medium.com/code-better/css-units-for-font-size-px-em-rem-79f7e592bb97
    // Usually default font-size of the browser is 16px. Setting font-size: 100% will make 1rem = 16px. 
    // But it will make calculations a little difficult. A better way is to set font-size: 62.5%. 
    // Because 62.5% of 16px is 10px. Which makes 1rem = 10px.
    // font-size: 62.5%;
    // font-size: 100%;
    // font-size: 14px;

    @include media-breakpoint-down(sm) {
        // font-size: .875rem /* 14/16 */;
    }

    body {
        font-family: 'GorditaRegular';
        color: $font-main-color;
    }
}

html,
body {
    overflow-x: hidden;
}

// Override button
.btn {
    font-family: GorditaBold;
    border-radius: 50rem;
    padding-top: .3125rem
        /* 5/16 */
    ;
    font-size: .875rem
        /* 14/16 */
    ;
    padding: 0.8em 0.6em 0.6em 0.6em;
    padding: 0.8em 1em 0.6em 1em;
    outline: none !important;
}

.btn-lg {
    padding: 1.2em 1em 1em 1em;
    // cursor: pointer;
}

.btn-primary,
.btn-outline-primary {
    color: #ffffff;

    &:hover {
        color: #ffffff;
    }
}

.btn-outline-light {
    border-width: 2px;
    color: #ffffff;
    border-color: #ffffff;

    &:hover {
        color: theme-color("primary");
    }
}

.btn-light {
    background-color: #ffffff;
    color: theme-color("primary");

    &:hover {
        color: theme-color("primary");
    }
}

h1,
h2,
h3 {
    font-family: GorditaBold;
}

h1 {
    // font-size: 2.25rem /* 36/16 */;
    font-size: 1.875rem
        /* 30/16 */
    ;

    @include media-breakpoint-up(md) {
        font-size: 2.625rem
            /* 42/16 */
        ;
        line-height: 3.3125rem
            /* 53/16 */
        ;
    }
}

// h2 {
//     font-family: GorditaMedium;
//     font-size: 1.5rem
//         /* 24/16 */
//     ;

//     @include media-breakpoint-up(md) {
//         font-size: 2.25rem
//             /* 36/16 */
//         ;
//         // line-height: 3.3125rem
//         //     /* 53/16 */
//         // ;
//     }
// }

.tb-subtitle {
    font-family: GorditaRegular;
    // letter-spacing: 0.25px;
    line-height: 1.875rem
        /* 30/16 */
    ;
}

.tb-page-header {
    margin: 3.125rem
        /* 50/16 */
        0;

    @include media-breakpoint-up(md) {
        margin: 9.375rem
            /* 150/16 */
            0 5rem
            /* 80/16 */
            0;
    }

    .tb-page-header-subtitle {
        margin: 15px 0 15px 0;
    }
}

.tb-nav-bg-1 {
    position: absolute;
    z-index: -1;
    width: 900px;
    top: -30px;
    display: none;

    // right: -830px;
    // @include media-breakpoint-only(sm) {
    //     right: -760px;
    // }
    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-only(md) {
        right: -400px;
    }

    @include media-breakpoint-only(lg) {
        right: -180px;
    }

    @include media-breakpoint-only(xl) {
        right: 0;
    }

    @media only screen and (min-width: 1700px) {
        display: none;
    }
}

.tb-header {
    .navbar {
        padding: .625rem
            /* 10/16 */
            0;

        @include media-breakpoint-up(md) {
            padding: 3.75rem
                /* 60/16 */
                0 0 0;
        }

        .navbar-brand {
            img {
                // height: 1.875rem /* 30/16 */;
                height: 2.4rem;

                @include media-breakpoint-down(sm) {
                    height: 1.5625rem
                        /* 25/16 */
                    ;
                }
            }
        }

        // Dropdown on hover
        .dropdown:hover>.dropdown-menu {
            display: block;
        }

        .navbar-nav {
            flex-direction: row;

            .dropdown-menu {
                position: absolute;
                // padding: .625rem
                //     /* 10/16 */
                // ;
                margin: 0;
                padding: 0;
                box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
                border: none;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                a {
                    // padding: .625rem
                    // /* 10/16 */
                    // ;
                    border-radius: 0;
                }

                &.tb-language-dropdown {
                    min-width: 0;
                }

                &.tb-product-dropdown {
                    width: 350px;

                    .dropdown-item {
                        white-space: normal; // allow text to wrap
                    }

                    a {
                        padding: 25px;

                        .tb-link-title {
                            text-transform: uppercase;
                            color: inherit;
                            font-family: GorditaBold;
                            margin-bottom: 5px;
                        }

                        .tb-link-desc {
                            color: $font-main-color;
                        }
                    }
                }

            }
        }

        .nav-item {
            a {
                color: inherit;

                @include media-breakpoint-up(md) {
                    color: #ffffff;
                }

                font-weight: bold;
                font-size: .875rem
                /* 14/16 */
                ;
                border-radius: 50rem;
                padding: 0.8em 1em 0.6em 1em;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                    text-decoration: none;
                }
            }

            &.dropdown {
                .nav-link {
                    &.tb-hamburger {
                        font-size: 1.25rem
                            /* 20/16 */
                        ;
                    }

                    &.tb-language-mobile {
                        color: theme-color("primary");
                    }
                }

                .dropdown-item {
                    color: theme-color("primary");
                }

                @include media-breakpoint-down(sm) {
                    position: static;
                }
            }
        }

        &.fixed-top {
            padding: .625rem
                /* 10/16 */
                0 .625rem
                /* 10/16 */
                0;
            background-color: #ffffff;
            z-index: 2;
            box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
            // transition: all .2s ease-in-out;
            // transition: all 200ms ease-out;
            transition: background-color 200ms ease-out;

            .nav-item {
                a {
                    // color: theme-color("primary");
                    color: inherit;
                }
            }

            .btn-light {
                background-color: theme-color("primary");
                color: #ffffff;
            }

            .btn-outline-light {
                color: theme-color("primary");
                border-color: theme-color("primary");
            }
        }

        @media only screen and (min-width: 1750px) {
            background-color: #ffffff;
            z-index: 2;

            .nav-item {
                a {
                    color: inherit;
                }
            }

            .btn-light {
                background-color: theme-color("primary");
                color: #ffffff;
            }

            .btn-outline-light {
                color: theme-color("primary");
                border-color: theme-color("primary");
            }
        }



    }
}

.tb-hero {
    margin: 1.875rem
        /* 30/16 */
        0;

    @include media-breakpoint-only(md) {
        margin: 5rem
            /* 80/16 */
            0;
    }

    @include media-breakpoint-up(lg) {
        margin: 12.5rem
            /* 200/16 */
            0;
        // margin-top: 2.5rem /* 40/16 */;    
        // margin-bottom: 12.5rem /* 200/16 */;    
    }

    .tb-info {
        @include media-breakpoint-down(md) {
            text-align: center;
        }

        .tb-hero-subtitle {
            font-size: 1.125rem
                /* 18/16 */
            ;
            letter-spacing: 0.28px;
            line-height: 28px;
        }
    }


    .tb-illust {

        @include media-breakpoint-down(md) {
            margin-bottom: 3.125rem
                /* 50/16 */
            ;
        }

        @include media-breakpoint-up(xl) {
            position: absolute;
            right: -300px;
            top: -100px;
            width: 950px;
            max-width: 950px;
        }

    }


}


.tb-how-it-works {
    @include media-breakpoint-down(md) {
        text-align: center;
    }

    .tb-img-wrapper {
        position: relative;
        margin: 0 0 1.875rem
            /* 30/16 */
            0;

        .tb-illust {
            max-height: 25rem
                /* 400/16 */
            ;

            border-radius: 6px;
            border: 1px solid #EAEBEE;
            margin: 5px 0;

            // @include media-breakpoint-up(lg) {
            //     margin-right: 2.5rem /* 40/16 */;
            // }
        }

        .tb-bg-1 {
            position: absolute;
            top: -3.75rem
                /* 60/16 */
            ;
            left: -3.125rem
                /* 50/16 */
            ;
            z-index: -1;
        }

        .tb-bg-2 {
            position: absolute;
            bottom: -2.5rem
                /* 40/16 */
            ;
            right: -1.25rem
                /* 20/16 */
            ;
            z-index: -1;
        }
    }

    .tb-description {
        @include media-breakpoint-up(lg) {
            padding-left: 2.5rem
                /* 40/16 */
            ;
        }
    }
}

.tb-how-it-works-steps {
    margin-top: 9.375rem
        /* 150/16 */
    ;
    position: relative;

    @include media-breakpoint-up(md) {
        margin: 9.375rem
            /* 150/16 */
            0;
    }

    .tb-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
        padding: 0 1.875rem
            /* 30/16 */
            1.875rem
            /* 30/16 */
            1.875rem
            /* 30/16 */
        ;
        margin-bottom: 5rem
            /* 80/16 */
        ;

        @include media-breakpoint-up(md) {
            height: 100%;
            margin-bottom: 0;
        }

        .tb-step-number {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 3px;
            color: #ffffff;
            font-size: 1.25rem
                /* 20/16 */
            ;
            font-family: GorditaBold;
            background-color: theme-color("primary");
            border-radius: 50%;
            height: 64px;
            width: 64px;
            top: -32px;
            position: absolute;
        }

        .tb-step-title {
            font-family: GorditaMedium;
            font-size: 1.25rem
                /* 20/16 */
            ;
            letter-spacing: 0;
            line-height: 32px;
            padding-top: 3.75rem
                /* 60/16 */
            ;
            margin: 0;
        }

        // .tb-step-desc {
        //     line-height: 30px;
        // }
    }


    .tb-bg-1 {
        position: absolute;
        top: -70px;
        left: 0;
        z-index: -1;
    }

    .tb-bg-2 {
        position: absolute;
        top: -40px;
        right: 90px;
        z-index: -1;
    }

}

.tb-benefits {
    img {
        margin: 1.875rem
            /* 30/16 */
            0;

        @include media-breakpoint-up(md) {
            margin: 4.375rem
                /* 70/16 */
                3.125rem
                /* 50/16 */
            ;
        }
    }
}

.tb-testimonials {
    margin: 9.375rem
        /* 150/16 */
        0;
    position: relative;

    .tb-testimonial {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 0 30px 30px 30px;
        box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);

        .tb-avatar {
            border-radius: 50%;
            height: 64px;
            width: 64px;
            align-self: center;
            position: relative;
            top: -32px;
        }

        .tb-quote {
            // font-family: GorditaRegular-Italic;
            font-size: 20px;
            // letter-spacing: 0;
            line-height: 32px;
            font-style: italic;
        }

        .tb-name {
            font-family: GorditaBold;
            font-size: 16px;
            letter-spacing: 0.25px;
            line-height: 30px;
        }

        .tb-job-title {
            // color: $grey;
        }
    }


    .tb-bg-1 {
        position: absolute;
        top: -30px;
        left: -70px;
        z-index: -1;
    }

    .tb-bg-2 {
        position: absolute;
        top: -80px;
        right: -150px;
        z-index: -1;
    }

}

.tb-press {
    img {
        padding: 1.25rem
            /* 20/16 */
    }
}

.tb-register {
    margin-top: 3.125rem
        /* 50/16 */
    ;

    @include media-breakpoint-up(lg) {
        margin-top: 9.375rem
            /* 150/16 */
        ;
    }

    position: relative;

    .tb-form-wrapper {
        display: flex;
        flex-direction: column;
        background-color: theme-color("primary");
        color: #FFFFFF;
        padding: 100px 20px;

        .tb-form-title {
            font-size: 26px;
            font-weight: bold;
            // line-height: 53px;

            @include media-breakpoint-up(md) {
                font-size: 36px;
                font-weight: bold;
                line-height: 53px;
            }
        }

        .tb-form-desc {
            font-size: 16px;
            // letter-spacing: 0.25px;
            line-height: 36px;
            margin-bottom: 20px;
        }

        .tb-form {
            position: relative;
            width: 100%;
            align-self: center;

            input {
                background: #678EFF;
                color: #FFFFFF;
                border-radius: 50rem;
                // opacity: 0.5;
                border: none;
                font-size: 13px;
                font-weight: bold;
                line-height: 49px;
                padding: 2px 20px;
                // border: 1px solid #ffffff;
                outline: none;
                width: 100%;
                margin-bottom: 5px;

                &::placeholder {
                    opacity: 0.5;
                    color: #ffffff;
                }
            }

            button {
                color: #678EFF;
                border: 1px solid #678EFF;

                @include media-breakpoint-up(sm) {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }

    .tb-bg-1 {
        position: absolute;
        // top: 150px;
        left: 0;
        bottom: -80px;
        // bottom: 0;
        z-index: -1;
    }

    .tb-bg-2 {
        position: absolute;
        top: -30px;
        right: 130px;
        z-index: -1;
    }
}

.tb-modules {
    margin: 7.5rem
        /* 120/16 */
        0;

    a {
        text-decoration: none;
    }

    .tb-module-1 {

        // background-color: $tb-module-color-1;
        // border-top: 10px solid $tb-module-color-1;
        .tb-more-link {
            background-color: $tb-module-color-1;
        }
    }

    .tb-module-2 {
        // background-color: $tb-module-color-2;
        border-top: 10px solid $tb-module-color-2;
    }

    .tb-module-3 {
        // background-color: $tb-module-color-3;
        border-top: 10px solid $tb-module-color-3;
    }

    .tb-module-4 {

        // background-color: $tb-module-color-4;
        // border-top: 10px solid $tb-module-color-4;
        .tb-more-link {
            background-color: $tb-module-color-4;
        }
    }

    .tb-module-1,
    .tb-module-2,
    .tb-module-3,
    .tb-module-4 {
        // position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.875rem
            /* 30/16 */
        ;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.3);
        // color: #FFFFFF;
        color: $font-main-color;

        @include media-breakpoint-up(lg) {
            height: 100%;
            // &:hover {
            // transform: scale(1.1);
            // }
        }


        .tb-module-img {
            margin: 10px 0;
            width: 100px;
        }

        .tb-module-desc {
            // padding-top: 20px;
        }

        .tb-more-link {
            // position: absolute;
            // bottom: 10px;
            // display: inline-block;
            // align-self: flex-end;

            // background-color: #FFFFFF;
            border-radius: 25px;
            // color: #303857;
            color: #ffffff;
            padding: .9375rem
                /* 15/16 */
            ;
            text-align: center;
            text-transform: uppercase;
            font-size: .75rem
                /* 12/16 */
            ;
            font-family: GorditaBold;
            // margin-top: 1.25rem
            //     /* 20/16 */
            // ;

            // @include media-breakpoint-up(md) {
            // } 
            &:hover {
                transform: scale(1.05);
            }
        }

    }

}

.tb-product-tour {

    .tb-link-to-element {
        color: #FFFFFF;
    }

    // .tb-module-1,
    // .tb-module-2,
    // .tb-module-3,
    // .tb-module-4 {
    //     margin-bottom: 50px;
    //     @include media-breakpoint-up(md) {
    //         margin-bottom: 250px;
    //     }
    // }   

    .tb-module-1 {
        .tb-module-header {
            background-color: $tb-module-color-1;
        }

        .tb-feature-title {
            color: $tb-module-color-1;
        }
    }

    .tb-module-2 {
        .tb-module-header {
            background-color: $tb-module-color-2;
        }

        .tb-feature-title {
            color: $tb-module-color-2;
        }

        // .tb-feature-accordion-item {
        //     border-top: $tb-module-color-2 10px solid;
        // }
    }

    .tb-module-3 {
        .tb-module-header {
            background-color: $tb-module-color-3;
        }

        .tb-feature-title {
            color: $tb-module-color-3;
        }

        .tb-feature {
            // border-top: $tb-module-color-3 10px solid;
        }
    }

    .tb-module-4 {
        .tb-module-header {
            background-color: $tb-module-color-4;
        }

        .tb-feature-title {
            color: $tb-module-color-4;
        }

        .tb-feature {
            // border-top: $tb-module-color-4 10px solid;
        }
    }

    .tb-module-header {
        margin: 1.875rem
            /* 30/16 */
            0 0 0;
        padding: 4.375rem
            /* 70/16 */
            1.875rem
            /* 30/16 */
        ;
        color: #FFFFFF;
        border-radius: 10px;

        img {
            max-width: 100px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
            margin: 9.375rem
                /* 150/16 */
                0 6.25rem
                /* 100/16 */
                0;

            img {
                max-width: 200px;
                // Center image
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
            }

        }
    }

    .tb-feature-accordion {
        // margin-top: 6.25rem
        //     /* 100/16 */
        // ;

        padding: 20px 0 80px 0;

        @include media-breakpoint-up(md) {
            padding: 130px 0;
        }


        .tb-feature-img {
            box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.2);
            border-radius: 10px;
        }

        .tb-vertical-items {
            .tb-feature-accordion-item {
                margin-top: 20px;

                @include media-breakpoint-up(md) {
                    margin-top: 10px;
                    padding: 0 1.875rem
                        /* 30/16 */
                        1.875rem
                        /* 30/16 */
                        1.875rem
                        /* 30/16 */
                    ;
                    border-radius: 10px;
                    cursor: pointer;

                    &:hover {
                        // background-color: #F8F8F8;
                        background-color: #F7F9FA;
                    }

                    &.active {
                        background-color: #F7F9FA;
                        // background-color: #EAEBEE;

                    }
                }

                .tb-feature-title {
                    font-family: GorditaBold;
                    letter-spacing: 0;
                    padding: 1.875rem
                        /* 30/16 */
                        0 0 0;
                    text-transform: uppercase;
                }

            }
        }

        .tb-horizontal-items {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .tb-feature-accordion-item {
                margin: 50px 0 10px 0;
                text-align: center;

                @include media-breakpoint-up(md) {
                    padding: 20px 25px 15px 25px;
                    margin: 15px 10px;
                    // padding: 0 1.875rem
                    //     /* 30/16 */
                    //     1.875rem
                    //     /* 30/16 */
                    //     1.875rem
                    //     /* 30/16 */
                    // ;
                    border-radius: 50rem;
                    cursor: pointer;

                    &:hover {
                        background-color: #F8F8F8;
                        // background-color: #F7F9FA;
                    }

                    &.active {
                        background-color: #EAEBEE;
                        // background-color: #F7F9FA;

                    }
                }

                .tb-feature-title {
                    font-family: GorditaBold;
                    letter-spacing: 0;
                    // padding: 1.875rem
                    //     /* 30/16 */
                    //     0 0 0;
                    margin: 0;
                    font-size: 16px;
                    text-transform: uppercase;
                }

            }
        }

        .tb-feature-accordion-image-list {
            position: relative;
            height: 100%;

            .tb-feature-accordion-image {
                position: relative;
                // top: 0;
                // left: 0;
                // box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
                // border-radius: 10px;
                // border: #EAEBEE 1px solid;

                &.active {
                    z-index: 1;
                    // transition: transform .3s,opacity .3s,-webkit-transform .3s;
                    display: inherit;
                }

                &.inactive {
                    // z-index: 1;
                    display: none;
                }
            }

            .tb-bg-dots {
                position: absolute;
                bottom: -50px;
                right: -50px;
                z-index: 0;
            }
        }
    }

    .tb-feature-list {
        // margin: 2.5rem
        //     /* 40/16 */
        //     0;

        background-color: #F7F9FA;

        padding: 80px 0;

        @include media-breakpoint-up(md) {
            padding: 130px 0;
        }

        .tb-subtitle {
            margin-bottom: 40px;
        }

        .tb-feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;
            // border-top: #D6277B 10px solid;
            border-radius: 10px;
            box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
            padding: 0 1.875rem
                /* 30/16 */
                1.875rem
                /* 30/16 */
                1.875rem
                /* 30/16 */
            ;
            margin-bottom: 1.875rem
                /* 30/16 */
            ;

            @include media-breakpoint-up(md) {
                // height: 100%;
                // margin-bottom: 0;
            }

            img {
                width: 50px;
                padding-top: 15px;
            }

            .tb-feature-title {
                // font-size: 1.25rem /* 20/16 */;
                font-size: .75rem
                    /* 12/16 */
                ;
                font-family: GorditaBold;
                letter-spacing: 0;
                padding: 1.875rem
                    /* 30/16 */
                    0 .625rem
                    /* 10/16 */
                    0;
                text-transform: uppercase;
            }

            .tb-comming-soon-badge {
                border-radius: 6px;
                background-color: #EAEBEE;
                padding: 4px 10px;
                font-size: .6875rem
                    /* 11/16 */
                ;
                font-family: GorditaBold;
                text-transform: uppercase;


            }
        }
    }

    .tb-devices-img {
        margin-bottom: 80px;

        @include media-breakpoint-up(md) {
            margin-bottom: 150px;
        }
    }
}

.tb-pricing {
    .tb-pricing-plan-wrapper {
        // position: relative;
        margin-bottom: 1.25rem
            /* 20/16 */
        ;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            height: 100%;
        }

        .tb-pricing-plan {
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
            padding: 1.875rem
                /* 30/16 */
            ;

            @include media-breakpoint-up(md) {
                padding-bottom: 0;
                height: 100%;
            }

            .tb-title {
                opacity: 0.5;
                font-family: GorditaMedium;
                font-size: 13px;
                color: #313957;
                letter-spacing: 0.5px;
                line-height: 20px;
                margin-bottom: .9375rem
                    /* 15/16 */
                ;
            }

            .tb-price {
                font-family: GorditaRegular;
                font-size: 3.75rem
                    /* 60/16 */
                ;
                // color: #313957;
                letter-spacing: -0.44px;
            }

            .tb-period {
                font-family: GorditaMedium;
                font-size: 15px;
                letter-spacing: 2.68px;
                line-height: 30px;
                text-transform: uppercase
            }

            .tb-special-quote {
                font-family: GorditaRegular;
                font-size: 28px;
                letter-spacing: -0.25px;
                line-height: 38px;
                margin: 1.875rem
                    /* 30/16 */
                    0;
                // margin: auto 0;
            }
        }

        .tb-call-to-action {
            // position: relative;
            bottom: 20px;
            margin: 20px 0;
            box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
        }
    }

    .tb-faq {
        margin-top: 2.5rem
            /* 40/16 */
        ;

        @include media-breakpoint-up(lg) {
            margin-top: 9.375rem
                /* 150/16 */
            ;
        }

        h3 {
            margin-top: 1.875rem
                /* 30/16 */
            ;
        }

        // .card{
        //     border: none;
        //     .card-header{
        //         background-color: #ffffff;
        //         .btn{
        //             font-family: GorditaBold;
        //             font-size: 20px;    
        //             letter-spacing: 0.21px;
        //             color: inherit;
        //             padding: 0;
        //         }
        //     }
        //     .card-body{
        //         font-family: GorditaRegular;
        //         font-size: 16px;
        //         letter-spacing: 0.22px;
        //         line-height: 32px;
        //     }
        // }
    }
}

.tb-contact {
    @include media-breakpoint-up(lg) {
        margin-top: 9.375rem
            /* 150/16 */
        ;
    }

    .tb-contact-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 12px 25px 0 rgba(49, 57, 87, 0.09);
        padding: 1.875rem
            /* 30/16 */
        ;

        .tb-title {
            font-size: 18px;
        }

        .tb-avatar {
            max-width: 10rem
                /* 160/16 */
            ;
            max-height: 10rem
                /* 160/16 */
            ;
            // margin: 1.875rem /* 30/16 */;
            margin: 1.875rem
                /* 30/16 */
                3.75rem
                /* 60/16 */
            ;
            // border-radius: 50%;
        }

        .tb-name {
            font-family: GorditaBold;
            font-size: 24px;
        }

        .tb-info {
            font-size: 16px;
            line-height: 32px;
            margin: 1.875rem
                /* 30/16 */
                0;
        }

        .tb-mail {
            font-family: GorditaBold;
            font-size: 16px;
            line-height: 32px;
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }

        .tb-bg-1 {
            position: absolute;
            left: -6.25rem
                /* 100/16 */
            ;
            bottom: -6.25rem
                /* 100/16 */
            ;
            z-index: -1;
        }

        .tb-bg-2 {
            position: absolute;
            left: -5rem
                /* 80/16 */
            ;
            bottom: -1.5625rem
                /* 25/16 */
            ;
            z-index: -1;
        }
    }

}

.tb-contact-form {
    // margin: 250px 0 80px 0;
    margin-top: 6.25rem
        /* 100/16 */
    ;

    @include media-breakpoint-up(lg) {
        margin-top: 9.375rem
            /* 150/16 */
        ;
    }

    position: relative;

    .tb-form-wrapper {
        display: flex;
        flex-direction: column;
        background-color: theme-color("primary");
        color: #FFFFFF;
        padding: 50px 20px;

        .tb-form-title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 1.875rem
                /* 30/16 */
            ;
        }


        .tb-form {
            position: relative;
            align-self: center;

            .form-control {
                background: #678EFF;
                color: #FFFFFF;
                border-radius: 24px;
                border: none;
                font-size: 14px;
                font-weight: bold;
                padding: 20px;

                outline: none;
                width: 100%;
                margin: 15px 0;

                &::placeholder {
                    opacity: 0.5;
                    color: #ffffff;
                }
            }


            .btn {
                width: 100%;
                margin: 15px 0;
            }
        }
    }

    .tb-bg-1 {
        position: absolute;
        // top: 150px;
        left: 0;
        bottom: -80px;
        // bottom: 0;
        z-index: -1;
    }

    .tb-bg-2 {
        position: absolute;
        top: -30px;
        right: 130px;
        z-index: -1;
    }
}

.tb-policy {
    margin: 1.875rem
        /* 30/16 */
        0;

    @include media-breakpoint-only(md) {
        margin: 5rem
            /* 80/16 */
            0;
    }

    @include media-breakpoint-up(lg) {
        margin: 9.375rem
            /* 150/16 */
            0;
    }
}

.tb-footer-container {
    margin-top: 4.375rem
        /* 70/16 */
    ;

    background: $dark;
    font-size: 15px;
    color: #FFFFFF;
    padding: 2rem 0;

    .tb-footer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        a {
            color: #ffffff;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                margin-right: 1rem;
                // flex-direction: row;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .tb-links {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                align-items: center;
                flex-direction: row;
            }
        }

        .tb-media {
            margin-top: .625rem
                /* 10/16 */
            ;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            i {
                margin-right: .625rem
                    /* 10/16 */
                ;

                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }

                font-size: 1.875rem
                /* 30/16 */
                ;
            }
        }
    }
}

.tb-registration-page {

    .navbar-nav {
        display: none;
    }

    .tb-page-header {
        margin-bottom: 0;
    }

    .tb-register {
        margin-top: 2rem;

        .tb-form-wrapper {
            color: inherit;
            background-color: transparent;
            padding: 0;
        }

        img {
            display: none !important;
        }
    }

    .tb-testimonials {
        .tb-bg-2 {
            display: none !important;
        }
    }
}


.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    height: 700px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        margin-top: 3.125rem
            /* 50/16 */
        ;
        // height: 80vh;
    }
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

::-webkit-scrollbar {
    display: none; // Safari and Chrome
    width: 0;
    height: 0;
}

.tb-blog {
    .tb-post {
        box-shadow: 0 10px 20px 0 rgba(49, 57, 87, 0.10);
        border-radius: 15px;
        margin-bottom: 50px;
        overflow: hidden;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        .tb-post-title {
            // margin: 0;
            padding: 30px 50px;
            color: $font-main-color;
            // font-size: 32px;
        }
    }
}

.tb-blog-post {
    margin: 3.125rem
        /* 50/16 */
        0;

    h2 {
        margin: 30px 0 20px 0;
    }
    h3 {
        margin: 30px 0 20px 0;
    }

    @include media-breakpoint-up(md) {
        margin: 9.375rem
            /* 150/16 */
            0;

        p {
            line-height: 1.75rem
                /* 28/16 */
            ;
            font-size: 1.125rem
                /* 18/16 */
            ;
        }

        // h1, h2 {
        //     margin-bottom: 20px;
        // }

        h2 {
            // margin-top: 50px;
            margin: 50px 0 20px 0;
        }
    }

    .tb-blog-post-title {
        // margin-bottom: 40px;
    }

    img {
        box-shadow: 0 10px 20px 0 rgba(49, 57, 87, 0.10);
        border-radius: 15px;
        margin: 30px 0 50px 0;

        max-width: 100%;
        height: auto;
    }
}

.tb-blog-post-signup {
    margin-bottom: 16px;
    border-radius: 15px;
    background-color: #F7F9FA;
    // background-color: #EAEBEE;
    // box-shadow: 0 10px 20px 0 rgba(49, 57, 87, 0.10);

    padding-top: 20px;

    @include media-breakpoint-up(md) {
        padding: 25px 0 0 10px;
    }

    .tb-signup-wrapper {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .tb-signup-title {
            font-family: GorditaMedium;
            padding: 0 20px;
        }
    }
}

.tb-404 {
    .message {
        padding: 200px 0;
        text-align: center;
    }
}

.tb-action-buttons {
    .btn {
        box-shadow: 0 10px 25px 0 rgba(103, 142, 255, 0.25);
        margin-right: .625rem
            /* 10/16 */
        ;
        margin-bottom: 1.25rem
            /* 20/16 */
        ;
    }
}

// .has-vertically-aligned-content {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }



// @include from($tablet) until($widescreen) {
//     border-left: 1px solid yellow;
//   }

// @include widescreen-only {
//     border-right: 2px solid blue;
//   }